
import { Component, Mixins, Watch } from '~/utility/pu-class-decorator'
import AuthMixin from '~~/mixins/AuthMixin'
import { useFavouritesStore } from '~/stores/useFavouritesStore'

@Component({
  setup() {
    const favStore = useFavouritesStore()
    return { favStore }
  },
})
export default class AppHeaderFavouritesCount extends Mixins(AuthMixin) {
  get favCount() {
    return this.favStore.totalCount
  }
}
